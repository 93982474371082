* {
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: #DDE3E9;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  padding: 8rem;
}

.main {
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: auto;
  padding: 32px;
  max-width: 496px;
}

.title {
  color: #333333;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 8px;
}

.description {
  color: #5D6365;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 600px) {
  .container {
    padding: 2rem 1rem;
  }

  .main {
    gap: 40px;
    padding: 24px;
    width: -moz-calc(100% - 48px);
    width: calc(100% - 48px);
  }
}