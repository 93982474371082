.form--description {
  color: #5d6365;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

button {
  align-self: flex-end;
  background: #329db5;
  border: 1px solid #329db5;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 16px;
}