.failure {
  align-items: flex-start;
  align-self: stretch;
  background: #FFE0E3;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex: none;
  gap: 8px;
  order: 0;
  padding: 8px;
  width: 100%;
}

.failure--description {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}