.loader--container {
  padding: 3.5rem 14.25rem;
}

.loader--spinner {
  animation: rotation 1s linear infinite;
  border-radius: 50%;
  border: 5px solid #dde3e9;
  border-bottom-color: #329db5;
  border-right-color: #329db5;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  width: 40px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}